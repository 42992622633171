// Package Imports

import React, { useState } from "react";

import Box from "@mui/material/Box";
import { Button, Modal } from "antd";
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import RedditIcon from '@mui/icons-material/Reddit';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LinkIcon from '@mui/icons-material/Link';
// Mapped Components

// Custom Component Functions
// import {
//   handleSearchFunctionality,
//   filterFunctionality,
// } from "../api/componentFunctions";

// Static Data
// import { allStates } from "../staticData/allStates";

const ShareAndDonate = (props) => {
  // const [allLocations, setAllLocations] = useState({});
  const [shareModal, setShareModal] = useState(false);
  const [donateModal, setDonateModal] = useState(false);

  const imageUrl = "";

  return (

    <Box className="flex flex-row w-full mt-10">
        <Button
            className="w-1/2 mx-1 bg-green-500 text-white"
            onClick={() => {
            setShareModal(true);
            }}
        >
            Share
        </Button>

        <Button
            className="w-1/2 mx-1 bg-black text-white"
            onClick={() => {
            setDonateModal(true);
            }}
        >
            Donate
        </Button>

        <Modal
            title="Share on all platforms"
            className=" bg-slate-500 flex justify-center"
            centered
            open={shareModal}
            onOk={() => setShareModal(false)}
            onCancel={() => setShareModal(false)}
            okText="OK"
            // bodyStyle={{ backgroundColor: "red" }}
            cancelButtonProps={{
            style: { backgroundColor: "black", color: "white" },
            }}
            okButtonProps={{
            style: { backgroundColor: "green", color: "white" },
            }}
        >
            {/* <Button href="https://twitter.com/compose/tweet" target="_blank">
            Hello
            </Button> */}

            <Box className="flex flex-row justify-center w-full my-10 gap-1">
                <Button
                href={`https://twitter.com/RatemyBudget`}
                target="_blank"
                className="bg-blue-500"
                >
                    <TwitterIcon color="inherit" className="text-white" />
                </Button>

                <Button
                href={`https://www.youtube.com/@ratemybudget/featured`}
                target="_blank"
                className="bg-red-500"
                >
                    <YouTubeIcon color="inherit" className="text-white" />
                </Button>

                
                <Button
                href={`https://Reddit.com`}
                target="_blank"
                className="bg-orange-500"
                >
                    <RedditIcon color="inherit" className="text-white" />
                </Button>

                <Button
                href={`https://twitter.com/LinkedIn`}
                target="_blank"
                className="bg-blue-800"
                >
                    <LinkedInIcon color="inherit" className="text-white" />
                </Button>

                <Button
                // href={`https://www.UniqueBudget.com`}
                target="_blank"
                className="bg-black"
                onClick={() =>  navigator.clipboard.writeText('https://www.UniqueBudget.com')}
                >
                    <LinkIcon color="inherit" className="text-white" />
                </Button>

            </Box>

            <Box className="flex flex-row justify-center w-full my-10 gap-1">
            <Button
                href={`https://www.instagram.com/ratemybudget/`}
                target="_blank"
                className="bg-pink-700"
                >
                    <InstagramIcon color="inherit" className="text-white" />
                </Button>

                <Button
                href={`https://discord.gg/MZdk4WHmgq`}
                target="_blank"
                className="bg-white"
                >
                    <img
                        src={require("../assets/discord.png")}
                        className=""
                        alt="unique budget calculator"
                        height="30"
                        width="26"
                    />
                </Button>           
                <Button
                href={`https://twitter.com/compose/tweet?text=My%20tweet%20text&media=${imageUrl}`}
                target="_blank"
                className="bg-green-500"
                >
                    <WhatsAppIcon color="inherit" className="text-white" />
                </Button>

                <Button
                href={`https://www.facebook.com/ratemybudget/`}
                target="_blank"
                className="bg-indigo-600"
                >
                    <FacebookIcon color="inherit" className="text-white" />
                </Button>

                <Button
                href={`https://www.tiktok.com/@ratemybudget`}
                target="_blank"
                className="bg-white"
                >
                    <img
                        src={require("../assets/tiktok.png")}
                        className=""
                        alt="unique budget calculator"
                        height="22"
                        width="22"
                    />
                </Button>
                {/* <Button onClick={() => {}}>Save image</Button> */}
            </Box>
            
        </Modal>

        <Modal
            title="Donation Options"
            className="justify-center"
            centered
            open={donateModal}
            onOk={() => setDonateModal(false)}
            onCancel={() => setDonateModal(false)}
            cancelButtonProps={{
            style: {
                justifyContent: "center",
                alignItems: "center",
            },
            }}
            okButtonProps={{
            style: {
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "green",
            },
            }}
        >
            <Box className="flex justify-center space-x-5 my-10">
            <Button
                href="https://paypal.me/uniquebudget?country.x=US&locale.x=en_US"
                target="_blank"
                className="bg-blue-800 text-white"
            >
                Paypal
            </Button>
            <Button
                href="https://www.buymeacoffee.com/budget"
                target="_blank"
                className="bg-yellow-400 text-black"
            >
                BuyMeACoffee
            </Button>
            <Button
                href="https://buy.stripe.com/cN2eVh2EmfKN3AYeUU"
                target="_blank"
                className="bg-indigo-700 text-white"
            >
                Stripe
            </Button>
            </Box>
        </Modal>
    </Box>
  );
};

export default ShareAndDonate;
