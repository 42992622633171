// Package Imports
import React, { useEffect, useState } from "react";
import { InputNumber, Input, Button, Radio } from "antd";
import Box from "@mui/material/Box";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Pie, Bar } from "react-chartjs-2";
import { ResponsiveSankey } from "@nivo/sankey";

// Custom Imports
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { revenueIncome, expenseCosts } from "../staticData/initialState";
import { addToState } from "../api/componentFunctions";
import {
  formatter,
  // parser,
  changeInputNames,
  calculateMoney,
  calculatePercentage,
  updateTotalSalary,
  getIncomeExpenseStateNames,
  getIncomeExpenseStateValues,
  getBackgroundColor,
  getBorderColor,
  getFederalTaxes,
  getTotalIncome,
  getTotalExpenses,
  getSubtotal,
  sankeyNodesData,
  sankeyLinksData,
  updateStatesRadioChange,
  // division,
} from "../api/componentFunctions";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const BudgetCalculatorComponent = (props) => {
  let income = revenueIncome;
  let expense = expenseCosts;

  if (
    localStorage.getItem("expenseInputs") != null &&
    localStorage.getItem("incomeInputs") != null
  ) {
    income = JSON.parse(localStorage.getItem("incomeInputs"));
    expense = JSON.parse(localStorage.getItem("expenseInputs"));
  }
  const [incomeInputs, setIncomeInputs] = useState(income);
  const [expenseInputs, setExpenseInputs] = useState(expense);
  const [totalMoney, setTotalMoney] = useState(0);
  const [size, setSize] = useState("yearly");

  // console.log("renderComponent");
  useEffect(() => {
    calculatePercentage(setIncomeInputs);
    calculatePercentage(setExpenseInputs);
  }, []);

  useEffect(() => {
    localStorage.setItem("expenseInputs", JSON.stringify(expenseInputs));
    localStorage.setItem("incomeInputs", JSON.stringify(incomeInputs));
    updateTotalSalary(incomeInputs, expenseInputs, setTotalMoney);
  }, [expenseInputs, incomeInputs]);

  const textInputsRevenue = incomeInputs.map((item) => (
    <Input
      className="w-full bg-green-200  dark:bg-green-400"
      theme="dark"
      type="line"
      defaultValue={item.id}
      value={item.id}
      onChange={(event) => {
        changeInputNames(item, event.target.value, setIncomeInputs);
      }}
    />
  ));

  const numberInputsRevenue = incomeInputs.map((item) => (
    <InputNumber
      className="w-full bg-green-200  dark:bg-green-400 "
      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      prefix="$"
      min={0}
      defaultValue={item.total}
      onChange={(value) => {
        // calculateMoney sets user input value to state
        calculateMoney(item, value, setIncomeInputs);
        // checks percentages and recalculates if necessary
        calculatePercentage(setIncomeInputs);
      }}
      value={item.total}
    />
  ));

  const percentInputsRevenue = incomeInputs.map((item) => (
    <InputNumber
      className="w-full bg-green-200  dark:bg-green-400 "
      value={item.percentage}
      min={0}
      max={100}
      formatter={(value) => `${value}%`}
      parser={(value) => value.replace("%", "")}
      onChange={(value) => {}}
    />
  ));

  const removeButtonsRevenue = incomeInputs.map((item) => (
    <RemoveCircleIcon
      shape="circle"
      className="text-red-400 my-0.5"
      fontSize="large"
      onClick={(event) => {
        const newArray = incomeInputs.filter(
          (element) => element.id !== item.id
        );
        setIncomeInputs(newArray);
      }}
    >
      -
    </RemoveCircleIcon>
  ));

  const textInputsExpense = expenseInputs.map((item) => (
    <Input
      className="w-full bg-red-200 dark:bg-red-400"
      theme="dark"
      type="line"
      defaultValue={item.id}
      value={item.id}
      onChange={(event) => {
        changeInputNames(item, event.target.value, setExpenseInputs);
      }}
    />
  ));

  const numberInputsExpense = expenseInputs.map((item) => (
    <InputNumber
      className="w-full bg-red-200 dark:bg-red-400 "
      formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
      prefix="$"
      min={0}
      defaultValue={item.total}
      onChange={(value) => {
        // calculateMoney sets user input value to state
        calculateMoney(item, value, setExpenseInputs);
        // checks percentages and recalculates if necessary
        calculatePercentage(setExpenseInputs);
      }}
      value={item.total}
    />
  ));

  const percentInputsExpense = expenseInputs.map((item) => (
    <InputNumber
      className="w-full bg-red-200 dark:bg-red-400"
      value={item.percentage}
      min={0}
      max={100}
      formatter={(value) => `${value}%`}
      parser={(value) => value.replace("%", "")}
      onChange={(value) => {}}
    />
  ));

  const removeButtonsExpense = expenseInputs.map((item) => (
    <RemoveCircleIcon
      shape="circle"
      className="text-red-400 mb-0.5 mt-0.5"
      fontSize="large"
      onClick={(event) => {
        const newArray = expenseInputs.filter(
          (element) => element.id !== item.id
        );
        setExpenseInputs(newArray);
      }}
    >
      -
    </RemoveCircleIcon>
  ));

  return (
    <Box className="h-full">
      {/* Tab Select */}
      <Box className="flex justify-center">
        <Radio.Group
          value={size}
          onChange={(e) => {
            setSize(e.target.value);
            updateStatesRadioChange(
              incomeInputs,
              expenseInputs,
              e.target.value
            );
            updateTotalSalary(incomeInputs, expenseInputs, setTotalMoney);
          }}
        >
          <Radio.Button value="monthly">Monthly</Radio.Button>
          <Radio.Button value="yearly">Yearly</Radio.Button>
        </Radio.Group>
      </Box>
      {/* Income Components */}
      <Box className="flex w-full pb-5 flex-col">
        <Box className="flex w-full py-5 font-semibold dark:text-white">
          Income Streams
        </Box>
        <Box className="flex flex-row w-full gap-5">
          <Box className="flex flex-col w-full gap-2">{textInputsRevenue}</Box>
          <Box className="flex flex-col w-full gap-2">
            {numberInputsRevenue}
          </Box>
          <Box className="flex flex-col w-full gap-2">
            {percentInputsRevenue}
          </Box>
          <Box className="flex flex-col">{removeButtonsRevenue}</Box>
        </Box>
      </Box>
      <Box className="flex flex-row w-full gap-5 mt-5">
        <Button className="flex flex-col w-full border-none bg-green-300  dark:bg-green-500">
          Total Income
        </Button>
        <Button className="flex flex-col w-full border-none bg-green-300  dark:bg-green-500">
          ${formatter(getTotalIncome(incomeInputs))}
        </Button>
        <Button className="flex flex-col w-full border-none bg-green-300  dark:bg-green-500"></Button>
        <AddCircleIcon
          shape="circle"
          className="text-green-400 pb-0.5"
          fontSize="large"
          onClick={(data) => {
            addToState(incomeInputs, setIncomeInputs, "", "income");
          }}
        >
          +
        </AddCircleIcon>
      </Box>
      {/* Expense Components */}
      <Box>
        <Box className="flex w-full py-5 font-semibold dark:text-white">
          Expenses
        </Box>
        <Box className="flex flex-row w-full gap-5">
          <Box className="flex flex-col w-full gap-2">{textInputsExpense}</Box>
          <Box className="flex flex-col w-full gap-2">
            {numberInputsExpense}
          </Box>
          <Box className="flex flex-col w-full gap-2">
            {percentInputsExpense}
          </Box>
          <Box className="flex flex-col">{removeButtonsExpense}</Box>
        </Box>
      </Box>
      {/* Addition Button */}
      <Box className="flex flex-row w-full gap-5 mt-5">
        <Button className="flex flex-col w-full border-none bg-red-300 dark:bg-red-500">
          Total Expenses
        </Button>
        <Button className="flex flex-col w-full border-none bg-red-300 dark:bg-red-500">
          ${formatter(getTotalExpenses(expenseInputs))}
        </Button>
        <Button className="flex flex-col w-full border-none bg-red-300 dark:bg-red-500"></Button>
        <AddCircleIcon
          shape="circle"
          className="text-green-400"
          fontSize="large"
          onClick={(data) => {
            addToState(expenseInputs, setExpenseInputs, "", "expense");
          }}
        >
          +
        </AddCircleIcon>
      </Box>
      {/* Total Summation */}

      <Box className="flex w-full py-5 font-semibold  dark:text-white">
        Summary
      </Box>
      <Box className="flex flex-row w-full gap-5">
        <Button className="flex flex-col w-full border-none bg-green-300  dark:bg-green-500">
          Subtotal
        </Button>
        <Button className="flex flex-col w-full border-none bg-green-300  dark:bg-green-500">
          ${formatter(getSubtotal(incomeInputs, expenseInputs))}
        </Button>
        <Button className="flex flex-col w-full border-none bg-green-300  dark:bg-green-500"></Button>
        <CancelIcon
          shape="circle"
          className="text-black dark:text-white"
          fontSize="large"
          onClick={(data) => {
            setExpenseInputs(expenseCosts);
            setIncomeInputs(revenueIncome);
            setTotalMoney(0);
            localStorage.setItem(
              "expenseInputs",
              JSON.stringify(expenseInputs)
            );
            localStorage.setItem("incomeInputs", JSON.stringify(incomeInputs));
          }}
        >
          X
        </CancelIcon>
      </Box>

      <Box className="flex flex-row w-full gap-5 mt-5">
        <Button className="flex flex-col w-full border-none bg-red-300 dark:bg-red-500">
          Federal Taxes
        </Button>
        <Button className="flex flex-col w-full border-none bg-red-300 dark:bg-red-500">
          ${formatter(getFederalTaxes(getTotalIncome(incomeInputs)))}
        </Button>
        <Button className="flex flex-col w-full border-none bg-red-300 dark:bg-red-500"></Button>
        <CancelIcon
          shape="circle"
          className="text-black dark:text-white"
          fontSize="large"
          onClick={(data) => {
            setExpenseInputs(expenseCosts);
            setIncomeInputs(revenueIncome);
            setTotalMoney(0);
            localStorage.setItem(
              "expenseInputs",
              JSON.stringify(expenseInputs)
            );
            localStorage.setItem("incomeInputs", JSON.stringify(incomeInputs));
          }}
        >
          X
        </CancelIcon>
      </Box>

      <Box className="flex flex-row w-full gap-5 mt-5">
        <Button className="flex flex-col w-full border-none bg-green-300  dark:bg-green-500">
          Total
        </Button>
        <Button className="flex flex-col w-full border-none bg-green-300  dark:bg-green-500">
          ${formatter(totalMoney)}
        </Button>
        <Button className="flex flex-col w-full border-none bg-green-300  dark:bg-green-500"></Button>
        <CancelIcon
          shape="circle"
          className="text-black dark:text-white"
          fontSize="large"
          onClick={(data) => {
            setExpenseInputs(expenseCosts);
            setIncomeInputs(revenueIncome);
            setTotalMoney(0);
            localStorage.setItem(
              "expenseInputs",
              JSON.stringify(expenseInputs)
            );
            localStorage.setItem("incomeInputs", JSON.stringify(incomeInputs));
          }}
        >
          X
        </CancelIcon>
      </Box>

      {/*Visualization */}
      <Box className="mt-10 font-semibold dark:text-white">Visualization</Box>

      {/* Sankey Chart */}
      <Box className="flex h-80 w-full">
        <ResponsiveSankey
          data={{
            nodes: sankeyNodesData(incomeInputs, expenseInputs),
            links: sankeyLinksData(incomeInputs, expenseInputs),
          }}
          label={(node) => {
            const formattedValue = String(node.value).replace(
              /\B(?=(\d{3})+(?!\d))/g,
              ","
            );
            return `${node.id}: $${formattedValue}`;
          }}
          margin={{ top: 40, bottom: 40, left: 160, right: 140 }}
          align="justify"
          colors={{ scheme: "category10" }}
          nodeOpacity={1}
          nodeHoverOthersOpacity={0.35}
          nodeThickness={18}
          nodeSpacing={18}
          nodeBorderWidth={0}
          nodeBorderColor={{
            from: "color",
            modifiers: [["darker", 0.8]],
          }}
          nodeBorderRadius={3}
          linkOpacity={0.5}
          linkHoverOthersOpacity={0.1}
          linkContract={3}
          enableLinkGradient={true}
          labelPosition="outside"
          labelOrientation="horizontal"
          labelPadding={3}
          labelTextColor={{
            from: "color",
            modifiers: [["darker", 1]],
          }}
        />
      </Box>
      {/* Pie Chart */}
      <Box className="flex justify-center mt-5 h-96">
        <Pie
          className="flex"
          options={{ maintainAspectRatio: false }}
          data={{
            // type: "pie",
            labels: getIncomeExpenseStateNames(incomeInputs, expenseInputs),
            datasets: [
              {
                labels: getIncomeExpenseStateNames(incomeInputs, expenseInputs),
                data: getIncomeExpenseStateValues(
                  incomeInputs,
                  expenseInputs,
                  "percentage"
                ),
                backgroundColor: getBackgroundColor(
                  incomeInputs,
                  expenseInputs
                ),
                borderColor: getBorderColor(incomeInputs, expenseInputs),
                borderWidth: 2,
                datalabels: {
                  formatter: function (value, context) {
                    return (
                      context.dataset.labels[context.dataIndex] +
                      ": " +
                      value +
                      "%"
                    );
                  },
                  font: {
                    size: 8,
                  },
                },
              },
            ],
          }}
        />
      </Box>
      {/* Bar Chart */}
      <Box className="flex">
        <Bar
          className="flex"
          data={{
            labels: getIncomeExpenseStateNames(incomeInputs, expenseInputs),
            datasets: [
              {
                labels: getIncomeExpenseStateNames(incomeInputs, expenseInputs),
                data: getIncomeExpenseStateValues(
                  incomeInputs,
                  expenseInputs,
                  "value"
                ),
                backgroundColor: getBackgroundColor(
                  incomeInputs,
                  expenseInputs
                ),
                borderWidth: 2,
                borderColor: getBorderColor(incomeInputs, expenseInputs),
                datalabels: {
                  formatter: function (value, context) {
                    const formattedValue = String(value).replace(
                      /\B(?=(\d{3})+(?!\d))/g,
                      ","
                    );
                    return (
                      context.dataset.labels[context.dataIndex] +
                      ": " +
                      "$" +
                      formattedValue
                    );
                  },
                  font: {
                    size: 10,
                  },
                },
              },
            ],
          }}
        />
      </Box>
    </Box>
  );
};

export default BudgetCalculatorComponent;
