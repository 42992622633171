// Package Imports
import React from "react";

// Custom Components
import CustomAppBar from "./components/AppBar";
import BudgetPage from "./pages/BudgetPage";
import AboutPage from "./pages/AboutPage";
import BudgetCalculatorComponent from "./components/BudgetCalculatorComponent";
import "./style.css";
import ReactGA from "react-ga4";
import { Route, Routes } from "react-router-dom";
import environmentVariables from "./envVariables";
import HomePage from "./pages/HomePage";

ReactGA.initialize(environmentVariables["GA_TRACKING_NUMBER"]);
ReactGA.send("pageview");

// const hash = window.location.hash;
// const hashParams = new URLSearchParams(hash.substr(1));
// const idToken = hashParams.get("id_token");
// const accessToken = hashParams.get("access_token");
// const refreshToken = hashParams.get("refresh_token");
// console.log(idToken);
// console.log(`access token: ${accessToken}`);
// console.log(`refresh token: ${refreshToken}`);

function App() {
  return (
    <div>
      <CustomAppBar title="" subTitle="here" />
      <Routes>
        <Route path="/" element={<BudgetPage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/state" element={<BudgetCalculatorComponent />} />
      </Routes>
      {/* <CustomAppBar title="" subTitle="share" /> */}
    </div>
  );
}

export default App;
