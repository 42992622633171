export const allData = [
    {
        "id": 0,
        "name": "John Funk",
        "image": "apple.png",
        "job": "Software Engineer",
        "state": "California",
        "income": 100000,
        "expenses": 50000,
        "assets": ["Real Estate", "Stocks"],
        "tags": ["Tech", "Software", "Engineering", "Bic Tech", "Fidelity"],
        "link": "https://upload.wikimedia.org/wikipedia/commons/a/a8/Bill_Gates_2017_%28cropped%29.jpg",
    },
    {
        "id": 1,
        "name": "Aqua",
        "image": "google.png",
        "job": "Doctor",
        "state": "Florida",
        "income": 300000,
        "expenses": 100000,
        "assets": ["Real Estate", "Debt"],
        "tags": ["Tech", "Software", "Engineering", "Bic Tech", "Fidelity"],
        "link": "https://cdn.britannica.com/04/171104-050-AEFE3141/Steve-Jobs-iPhone-2010.jpg",
    },
    {
        "id": 0,
        "name": "John Funk",
        "image": "apple.png",
        "job": "Software Engineer",
        "state": "California",
        "income": 100000,
        "expenses": 50000,
        "assets": ["Real Estate", "Stocks"],
        "tags": ["Tech", "Software", "Engineering", "Bic Tech", "Fidelity"],
        "link": "https://upload.wikimedia.org/wikipedia/commons/a/a8/Bill_Gates_2017_%28cropped%29.jpg",
    },
    {
        "id": 1,
        "name": "Aqua",
        "image": "google.png",
        "job": "Doctor",
        "state": "Florida",
        "income": 300000,
        "expenses": 100000,
        "assets": ["Real Estate", "Debt"],
        "tags": ["Tech", "Software", "Engineering", "Bic Tech", "Fidelity"],
        "link": "https://cdn.britannica.com/04/171104-050-AEFE3141/Steve-Jobs-iPhone-2010.jpg",
    }
]

console.log(allData)