// Package Imports

import React from "react";

// Mapped Components
import { Link } from "react-router-dom";

// Custom Component Functions
// import {
//   handleSearchFunctionality,
//   filterFunctionality,
// } from "../api/componentFunctions";

// Static Data
// import { allStates } from "../staticData/allStates";

const MainLogo = (props) => {
  // const [allLocations, setAllLocations] = useState({});

  return (

    <Link to="/">
        <img
          className="my-5"
          src={require("../assets/budgetlogo.png")}
          alt="unique budget calculator"
          height="100"
          width="200"
        />
    </Link>

  );
};

export default MainLogo;
