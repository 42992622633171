import { Card } from 'antd';
import { Link } from "react-router-dom";

const ProfileCard = (props) => (
//   <Link to="/">
    <Card
        title={props.value ? props.value.name : 'name'}
        bordered={true}
        extra = {<Link to="/">Explore My Budget!</Link>}
        className="w-full h-1/2 my-10 bg-green-400 dark:bg-green-600 shadow-lg rounded-3xl p-5"
    >
        <div className="flex flex-row gap-5">
            <div className="flex justify-center content-center w-1/4">
                <img
                    src={props.value ? props.value.link : 'link'}
                    className="rounded-full"
                    alt="unique budget calculator"
                    height="50"
                    width="90"
                />
            </div>
            <div className="w-1/4">
                <p>Job: {props.value ? props.value.job : 'job'}</p>
                <p>State: {props.value ? props.value.state : 'state'}</p>
                <p>Card content</p>
            </div>
            <div className="w-1/4">
                <p>Income: ${props.value ? props.value.income : 'income'}</p>
                <p>Expenses: ${props.value ? props.value.expenses : 'expenses'}</p>
                <p>Card content</p>
            </div>
            <div className="w-1/4">
                <p>Tags: {props.value ? props.value.tags.map((item) => {return item + ' '}) : 'income'}</p>
            </div>
        </div>

    </Card>  
//   </Link>
);



export default ProfileCard;