import React, { useState } from "react";
import Box from "@mui/material/Box";

// import Button from "@mui/material/Button";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { toogleDarkMode } from "../api/componentFunctions";
import { Switch, Drawer, Modal, Button } from "antd";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import environmentVariables from "../envVariables";

// import AccountCircleIcon from "@mui/icons-material/AccountCircle";
//import {LightModeIcon , DarkModeIcon} from '@mui/icons-material/DarkMode';

const CustomAppBar = (props) => {
  const environment = environmentVariables["ENVIRONMENT"];
  const domainName =
    environment === "prod" ? "uniquebudget.com" : "dev.uniquebudget.com";
  const GOOGLE_AUTH_ENDPOINT = `${environmentVariables["COGNITO_AUTH_ENDPOINT"]}/oauth2/authorize?identity_provider=Google&redirect_uri=https://${domainName}&response_type=CODE&client_id=${environmentVariables["COGNITO_CLIENT_ID"]}&scope=email openid profile`;

  let isLight =
    (localStorage.getItem("color-theme") &&
      localStorage.getItem("color-theme") === "light") ||
    false;
  const [switchChecked, setSwitchChecked] = useState(isLight);

  const [open, setOpen] = useState(false);

  const [loginModal, setLoginModal] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const showModal = () => {
    setLoginModal(true);
  };

  const closeModal = () => {
    setLoginModal(false);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar className="bg-green-600 dark:bg-green-700">
          <MenuIcon onClick={showDrawer} className="text-white mr-3" />
          <Typography
            className="dark:text-white"
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
          >
            <Link to="/">
              {window.innerWidth > 400 && window.innerHeight > 600
                ? "Unique Budget Calculator"
                : "UBC"}
            </Link>
          </Typography>
          <DarkModeIcon className="text-white" />
          <Switch
            className="mx-2 bg-gray-800"
            defaultChecked
            checked={switchChecked}
            onChange={() => {
              toogleDarkMode();
              localStorage.getItem("color-theme") &&
              localStorage.getItem("color-theme") === "light"
                ? setSwitchChecked(true)
                : setSwitchChecked(false);
            }}
          />
          <LightModeIcon className="text-white mr-3" />
          {/* <AccountCircleIcon
            color="inherit"
            onClick={showModal}
            className="mx-5 mt-0 text-white"
          /> */}
          <Button onClick={showModal} className="mx-5 mt-0 text-white">
            Sign In
          </Button>
        </Toolbar>
      </AppBar>
      <Drawer
        title="Budget Calculator Drawer"
        placement="left"
        onClose={onClose}
        open={open}
      >
        <p>
          <Link to="/" onClick={onClose}>
            Home
          </Link>
        </p>
        <p>
          <Link to="/home" onClick={onClose}>
            New Home Page
          </Link>
        </p>
        <p>
          <Link to="/about" onClick={onClose}>
            About
          </Link>
        </p>
        <p>
          <Link to="/state" onClick={onClose}>
            State
          </Link>
        </p>
        <p>Some contents...</p>
      </Drawer>
      <Modal
        title="Login Options"
        className="justify-center"
        centered
        open={loginModal}
        onOk={() => setLoginModal(false)}
        onCancel={() => setLoginModal(false)}
        cancelButtonProps={{
          style: {
            justifyContent: "center",
            alignItems: "center",
          },
        }}
        okButtonProps={{
          style: {
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "green",
          },
        }}
      >
        <Box className="flex justify-center space-x-5 my-10">
          {/* <Button
                href="https://paypal.me/uniquebudget?country.x=US&locale.x=en_US"
                target="_blank"
                className="bg-black text-white"
                onClick={closeModal}
            >
                Apple
            </Button> */}
          <Button
            href={GOOGLE_AUTH_ENDPOINT}
            target="_blank"
            className="text-white w-32 flex flex-row gap-1 ml-0"
            style={{ backgroundColor: "#3b5998" }}
            onClick={closeModal}
          >
            <img
              src={require("../assets/facebook.png")}
              className=""
              alt="unique budget calculator"
              // height="11"
              // width="16"
            />
            Facebook
          </Button>
          <Button
            href={GOOGLE_AUTH_ENDPOINT}
            target="_blank"
            className="bg-white text-black w-24 flex flex-row gap-1 ml-0"
            onClick={closeModal}
          >
            <img
              src={require("../assets/google.png")}
              className=""
              alt="unique budget calculator"
              // height="11"
              // width="16"
            />
            Google
          </Button>
          <Button
            href={GOOGLE_AUTH_ENDPOINT}
            target="_blank"
            className="bg-black text-white w-24 flex flex-row gap-1 ml-0"
            onClick={closeModal}
          >
            <img
              src={require("../assets/apple.png")}
              className="mr-2"
              alt="unique budget calculator"
              // height="11"
              // width="16"
            />
            Apple
          </Button>
          {/* <Button
                href="https://buy.stripe.com/cN2eVh2EmfKN3AYeUU"
                target="_blank"
                className="bg-indigo-700 text-white"
                onClick={closeModal}
            >
                Facebook
            </Button> */}
        </Box>
      </Modal>
    </Box>
  );
};

export default CustomAppBar;
